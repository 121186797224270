import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'ion-rangeslider/css/ion.rangeSlider.min.css';
import 'ion-rangeslider/js/ion.rangeSlider.min.js';
import "animate.css/animate.min.css";

import './App.scss';
const TheLayout = React.lazy(() => import('./containers/TheLayout'));
const PageNotFound = () => {
  return (
    "page not found"
  )
}
function App() {


  return (
    <>
        <ToastContainer />
        <Router>
      <React.Suspense>
        <Switch>
        <Route exact path="/" name="Home" render={(props) => <TheLayout {...props} />} />
        <Route path="/signup" name="Signup" render={(props) => <TheLayout {...props} />} />
        <Route path="/advertise" name="Advertise" render={(props) => <TheLayout {...props} />} />
        <Route path="/bidding" name="Bidding" render={(props) => <TheLayout {...props} />} />
        <Route path="/privacy-policy" name="PrivacyPolicy" render={(props) => <TheLayout {...props} />} />
        <Route path="/terms-and-conditions" name="TermsAndCondition" render={(props) => <TheLayout {...props} />} />
        <Route path="*" component={PageNotFound} />
        </Switch>
      </React.Suspense>
    </Router>
    </>
    
  );
}

export default App;
