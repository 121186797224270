import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { authHeader } from "../../apis/authHeader";
import HandleError from "../../apis/HandleError";
import axiosBaseURL from "../../apis/axiosBaseURL";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const CreateAdsCompanyAction = createAsyncThunk(
    "ads/CreateAdsCompanyAction",
    async (data) => {
        try {
            const response = await axiosBaseURL.post("/web/create_ads_company", data, {
                headers: authHeader(true)
            });
            return response.data;
        } catch (error) {
            HandleError(error.response.data)
            return error.response.data
        }
    }
);


export const AdsSlotListAction = createAsyncThunk(
    "ads/AdsSlotListAction",
    async (data) => {
        try {
            const response = await axiosBaseURL.post("/web/ads_slot_list", data, {
                headers: authHeader(true)
            });
            return response.data;
        } catch (error) {
            HandleError(error.response.data)
            return error.response.data
        }
    }
);

export const SetBidAmountAction = createAsyncThunk(
    "ads/SetBidAmountAction",
    async (data) => {
        try {
            const response = await axiosBaseURL.post("/web/set_slot_bid_amount", data, {
                headers: authHeader(true)
            });
            return response.data;
        } catch (error) {
            HandleError(error.response.data)
            return error.response.data
        }
    }
);

export const TermsAndConditionAction = createAsyncThunk(
    "ads/TermsAndConditionAction",
    async (data) => {
        try {
            const response = await axiosBaseURL.post("/web/terms_condition_list", data, {
                headers: authHeader(true)
            });
            return response.data;
        } catch (error) {
            HandleError(error.response.data)
            return error.response.data
        }
    }
);
export const privacyPolicyListAction = createAsyncThunk(
    "ads/privacyPolicyListAction",
    async (data) => {
        try {
            const response = await axiosBaseURL.post("/web/privacy_policy_list", data, {
                headers: authHeader(true)
            });
            return response.data;
        } catch (error) {
            HandleError(error.response.data)
            return error.response.data
        }
    }
);



const initialState = {
    createAds:{},
    setBidAmount:{},
    adsSlotList:{},
    adsLoading:false
};

const adsSlice = createSlice({
    name: "ads",
    initialState,
    reducers: {
        ResetCreateAdsCompanyAction : ((state) => {
            state.createAds = {};
        }),
        ResetAdsSlotListAction : ((state) => {
            state.adsSlotList = {};
        }),
        ResetSetBidAmountAction : ((state) => {
            state.setBidAmount = {};
        }),
    },
    extraReducers: {
        [CreateAdsCompanyAction.pending]: (state) => {
            return { ...state, adsLoading: true };
        },
        [CreateAdsCompanyAction.fulfilled]: (state, { payload }) => {
            if (payload.status) {
                toast.success(payload.message, { theme: "colored" })
                return { ...state, createAds: payload, adsLoading: false };
            }
            else {
                toast.error(payload.message, { theme: "colored" })
                return { ...state, createAds: payload, adsLoading: false };
            }
        },
        [CreateAdsCompanyAction.rejected]: (state) => {
            return { ...state, adsLoading: false };
        },

        [AdsSlotListAction.pending]: (state) => {
            return { ...state, adsLoading: true };
        },
        [AdsSlotListAction.fulfilled]: (state, { payload }) => {
            if (payload.status) {
                return { ...state, adsSlotList: payload, adsLoading: false };
            }
            else {
                toast.error(payload.message, { theme: "colored" })
                return { ...state, adsSlotList: payload, adsLoading: false };
            }
        },
        [AdsSlotListAction.rejected]: (state) => {
            return { ...state, adsLoading: false };
        },

        [SetBidAmountAction.pending]: (state) => {
            return { ...state, adsLoading: true };
        },
        [SetBidAmountAction.fulfilled]: (state, { payload }) => {
            if (payload.status) {
                //toast.success(payload.message, { theme: "colored" })
                return { ...state, setBidAmount: payload, adsLoading: false };
            }
            else {
                toast.error(payload.message, { theme: "colored" })
                return { ...state, setBidAmount: payload, adsLoading: false };
            }
        },
        [SetBidAmountAction.rejected]: (state) => {
            return { ...state, adsLoading: false };
        },
    }
})
export const { ResetCreateAdsCompanyAction, ResetAdsSlotListAction, ResetSetBidAmountAction,
    ResetMyServiceListAction } = adsSlice.actions;
export const getAdsLoading = (state) => state.ads.adsLoading;
export const getCreateAdsCompanyData = (state) => state.ads.createAds;
export const getAdsSlotListData = (state) => state.ads.adsSlotList
export const getSetBidAmountData = (state) => state.ads.setBidAmount
export default adsSlice.reducer;