import axios from "axios";

const server = {
  baseURL: "https://dev-api.futbolroyale.com/app"
  //baseURL: "http://localhost:5231/app"
};

export default axios.create({
  baseURL: server.baseURL,
});

export const SOCKET_URL = server.baseURL