import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const HandleError = error => {
    // toast.configure();
    if (error.status_code === 403) {
        localStorage.removeItem("auth_futball_web_security");
        //console.log("handleErrorOccur","Hnadle eero roccur")
        toast.error(error.message, { theme: "colored" })
        setTimeout(() => {
            window.location.href = "/"
        }, 700);
    }
};

export default HandleError;