import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { authHeader } from "../../apis/authHeader";
import axiosBaseURL from "../../apis/axiosBaseURL";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HandleError from "../../apis/HandleError";
import { Buffer } from "buffer";

export const RegisterAuthAction = createAsyncThunk(
    "auth/RegisterAuthAction",
    async (data) => {
        try {
            const response = await axiosBaseURL.post("/web/signup", data, {
                headers: authHeader(true)
            });
            return response.data;
        } catch (error) {
            return error.response.data
        }
    }
);

export const SaveEmailSubscribeAction = createAsyncThunk(
    "auth/SaveEmailSubscribeAction",
    async (data) => {
        try {
            const response = await axiosBaseURL.post("/web/saveEmailSubscribe", data, {
                headers: authHeader(true)
            });
            return response.data;
        } catch (error) {
            return error.response.data
        }
    }
);

/*export const LoginAuthAction = createAsyncThunk(
    "auth/LoginAuthAction",
    async (data) => {
        try {
            const response = await axiosBaseURL.post("/login", data, {
                headers: authHeader(true)
            });
            return response.data;
        } catch (error) {
            return error.response.data
        }
    }
);*/

/*export const LogoutAuthAction = createAsyncThunk(
    "auth/LogoutAuthAction",
    async () => {
        try {
            const response = await axiosBaseURL.post("/logout",{}, {
                headers: authHeader()
            });
            return response.data;
        } catch (error) {
            HandleError(error.response.data)
            return error.response.data
        }
    }
);*/


const initialState = {
    login: {},
    register:{},
    logout:{},
    emailSubs:{},
    isAuthenticated: false,
    loading: false 
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        /*setCurrentUser: ((state, { payload }) => {
            return { ...state, isAuthenticated: true};
        }),*/
        /*ResetLoginAction: ((state) => {
            return { ...state, login: {} };
        }),
        ResetLogoutAction: ((state) => {
            return { ...state, logout: {} };
        }),*/
        ResetRegisterAction: ((state) => {
            return { ...state, register: {} };
        }),
        ResetEmailSubscribeAction: ((state) => {
            return { ...state, emailSubs: {} };
        }),
        
    },
    extraReducers: {
        /*[LoginAuthAction.pending]: (state) => {
            return { ...state, loading: true };
        },
        [LoginAuthAction.fulfilled]: (state, { payload }) => {
            if (payload.status) {
                toast.success(payload.message, { theme: "colored" })
                return { ...state, login: payload, loading: false };
            }
            else {
                toast.error(payload.message, { theme: "colored" })
                return { ...state, login: payload, loading: false };
            }
        },
        [LoginAuthAction.rejected]: (state) => {
            return { ...state, loading: false };
        },*/


        /*[LogoutAuthAction.pending]: (state) => {
            return { ...state, loading: true };
        },
        [LogoutAuthAction.fulfilled]: (state, {payload}) => {
            if(payload.status) {
                toast.success(payload.message, { theme: "colored" })
                localStorage.removeItem("auth_rv_web_security")
                localStorage.removeItem("rv_web_role")
                return { ...state, logout:payload, loading: false, isAuthenticated: false };
            }
            else {
                toast.error(payload.message, { theme: "colored" })
                return { ...state, logout: payload, loading: false };
            }
           
        },
        [LogoutAuthAction.rejected]: (state) => {
            return { ...state, loading: false };
        },*/


        [RegisterAuthAction.pending]: (state) => {
            return { ...state, loading: true, };
        },
        [RegisterAuthAction.fulfilled]: (state, { payload }) => {
            if (payload.status) {
                //console.log("regsterpayloaddata",payload.message)
                toast.success(payload.message, { theme: "colored" })
                return { ...state, register: payload, loading: false };
            }
            else {
                toast.error(payload.message, { theme: "colored" })
                return { ...state, register: payload, loading: false };
            }
        },
        [RegisterAuthAction.rejected]: (state) => {
            return { ...state, loading: false};
        },

        [SaveEmailSubscribeAction.pending]: (state) => {
            return { ...state, loading: true, };
        },
        [SaveEmailSubscribeAction.fulfilled]: (state, { payload }) => {
            if (payload.status) {
                //console.log("regsterpayloaddata",payload.message)
                toast.success(payload.message, { theme: "colored" })
                return { ...state, emailSubs: payload, loading: false };
            }
            else {
                toast.error(payload.message, { theme: "colored" })
                return { ...state, emailSubs: payload, loading: false };
            }
        },
        [SaveEmailSubscribeAction.rejected]: (state) => {
            return { ...state, loading: false};
        },
    }
})
export const { ResetRegisterAction, ResetEmailSubscribeAction} = authSlice.actions;

export const getLoading = (state) => state.auth.loading;
//export const getLoginData = (state) => state.auth.login;
//export const getLogoutData = (state) => state.auth.logout;
export const getRegisterData = (state) => state.auth.register;
export const getEmailSubscribeData = (state) => state.auth.emailSubs;
export default authSlice.reducer;