import {Buffer} from "buffer"
export function authHeader(unAuth = null, isFile=null) {
    if (unAuth) {
        return {
            Accept: "application/json",
            "Content-Type": "application/json",
        };
    } else {
        let authToken = localStorage.getItem('auth_futball_web_security')
        authToken     = JSON.parse(Buffer.from(authToken, "base64"));
        //console.log("handleErrorOccur",authToken)

        if(isFile) {
            return {
                Accept: "multipart/form-data",
                "Content-Type": "multipart/form-data",
                Authorization: 'Bearer ' + authToken.access_token
            };
        } else {
            return {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: 'Bearer ' + authToken.access_token
            };
        }
       
    }
}